<template>
	<div class="home">

		<section class="page-head no-bg">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-8 py-6 text-center">
						<h1 class="display-5">Discover data behind domains</h1>
						<p class="lead">See expiry date, owner or domain history as you browse the web</p>
						<p>
							<a :href="link" target="_blank" class="btn btn-lg btn-primary px-5">Install now</a>
						</p>
					</div>
				</div>
			</div>
		</section>


		<section class="pb-6">

			<div class="container">

				<div class="row align-items-center justify-content-center">

					<div class="col-lg-7">
						<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
							<div class="carousel-inner">
								<div class="carousel-item active">
									<img src="/images/domain-info-extension.png?" alt="Domain Info extension - by Domains App" class="d-block w-100" />
								</div>
								<div class="carousel-item">
									<img src="/images/domain-info-extension-history.png" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item">
									<img src="/images/domain-info-extension-dns.png" class="d-block w-100" alt="...">
								</div>
							</div>

							<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
								<span class="carousel-control-prev-icon" aria-hidden="true"></span>
								<span class="visually-hidden">Previous</span>
							</button>
							<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
								<span class="carousel-control-next-icon" aria-hidden="true"></span>
								<span class="visually-hidden">Next</span>
							</button>
						</div>
						
					</div>

					<div class="col-lg-5">
						<ul class="nicer-list">
							<li>See domain availability: <span class="badge bg-success-lighter text-success">available</span>, <span class="badge bg-info-lighter text-info">registered</span> or <span class="badge border border-primary text-primary">for sale</span></li>
							<li>Show at which registrar the domain is registered</li>
							<li><strong>Owner info</strong>, like person or company name, location and contact email</li>
							<li>Important domain dates: registration date, expiry date</li>
							<li>DNS records: detects all subdomains added to a domain</li>
							<li><strong>History</strong>: see when changes happened in WHOIS or DNS Records</li>
							<li><strong>Does not track or record</strong> browser history, only domain name in current tab is used</li>
							<li>Uses <a href="https://github.com/LayeredStudio/domain-info-extension" target="_blank">Open-source code</a> that can be audited anytime</li>
						</ul>
					</div>

				</div>

			</div>

		</section>

		<section class="bg-primary-lighter">
			<div class="container py-6">

				<h2 class="text-center mb-4">Install for your browser</h2>

				<div class="row justify-content-center mb-4">
					<div class="col-6 col-md-4 col-lg-3 col-xl-2 my-3">
						<a href="https://chrome.google.com/webstore/detail/domain-info/afbepfhknfficaflckmgflbmklcleidl" target="_blank" class="card text-dark text-center">
							<div class="card-body">
								<img src="@/assets/icon-googlechrome.svg" width="100" class="mb-4" alt="dmns.app Chrome">

								<p class="mb-3">Add to Chrome</p>
							</div>
						</a>
					</div>

					<div class="col-6 col-md-4 col-lg-3 col-xl-2 my-3">
						<a href="https://addons.mozilla.org/en-US/firefox/addon/domain-info/" target="_blank" class="card text-dark text-center">
							<div class="card-body">
								<img src="@/assets/icon-firefoxbrowser.svg" width="100" class="mb-4" alt="dmns.app Firefox">

								<p class="mb-3">Add to Firefox</p>
							</div>
						</a>
					</div>

					<div class="col-6 col-md-4 col-lg-3 col-xl-2 my-3">
						<a href="https://microsoftedge.microsoft.com/addons/detail/domain-info/nhcnfhppmodmicifhggipekmmelpbbpe" target="_blank" class="card text-dark text-center">
							<div class="card-body">
								<img src="@/assets/icon-microsoftedge.svg" width="100" class="mb-4" alt="dmns.app Edge">

								<p class="mb-3">Add to Edge</p>
							</div>
						</a>
					</div>

					<div class="col-6 col-md-4 col-lg-3 col-xl-2 my-3">
						<div class="card text-dark text-center cursor-not-allowed">
							<div class="card-body">
								<img src="@/assets/icon-applesafari.svg" width="100" class="mb-3" alt="dmns.app Safari">

								<p class="mb-1">Add to Safari <small class="text-muted">(coming soon)</small></p>
							</div>
						</div>
					</div>
				</div>

				<p class="lead text-center mb-0">Domain information has never been so easy to access before!</p>
			</div>
		</section>

		<section class="container py-6">

			<div class="row justify-content-center text-center">
				<div class="col-6 col-md-4 col-lg-3 my-3">
					<span class="fs-1">🔒</span>
					<h2 class="mb-3">Private</h2>
					<p class="mb-2">Domain Info extension doesn't track or record any info about you or your browser history.</p>
					<p class="mb-0">Only the domain name of current tab is read when you click to open the extension.</p>
				</div>

				<div class="col-6 col-md-4 col-lg-3 my-3">
					<span class="fs-1">⚡️</span>
					<h2 class="mb-3">Fast</h2>
					<p class="mb-2">We wrote the fastest code possible to access WHOIS info and DNS Records.</p>
					<p class="mb-0">Along with cloud servers, it really is the fastest domain info tool we made.</p>
				</div>

				<div class="col-6 col-md-4 col-lg-3 my-3">
					<span class="fs-1">💰</span>
					<h2 class="mb-3">Free</h2>
					<p class="mb-2">This extension is free to use.</p>
					<p class="mb-2">It doesn't use or store any data from you or your browser.</p>
					<p class="mb-0">If you want to help us maintain it for the future, please sign up for a paid domain monitoring plan.</p>
				</div>
			</div>
		</section>

	</div>
</template>

<style type="text/css" lang="scss" scoped>
.carousel-control-prev,
.carousel-control-next {
	span {
		background-color: var(--bs-primary);
		border-radius: 50%;
	}
}
</style>

<script>

export default {
	name: 'Extension',
	computed: {
		isFirefox() {
			return navigator.userAgent.toLowerCase().includes('firefox')
		},
		isEdge() {
			return navigator.userAgent.toLowerCase().includes('edg/') || navigator.userAgent.toLowerCase().includes('edge')
		},
		isChrome() {
			return !this.isEdge && window.chrome && navigator.vendor === 'Google Inc.'
		},
		link() {
			let link = 'https://chrome.google.com/webstore/detail/domain-info/afbepfhknfficaflckmgflbmklcleidl'

			if (this.isEdge) {
				link = 'https://microsoftedge.microsoft.com/addons/detail/domain-info/nhcnfhppmodmicifhggipekmmelpbbpe'
			} else if (this.isFirefox) {
				link = 'https://addons.mozilla.org/en-US/firefox/addon/domain-info/'
			}

			return link
		}
	}
}
</script>
